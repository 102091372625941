import { Link } from "gatsby"
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import WorkDetail from "../components/work-detail"
import cs from "../images/Companies/3cs.png"
import tropusi from "../images/Companies/tropusi.png"
import yncu from "../images/Companies/yncu.png"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div id="intro" className="container is-fullwidth site-section">
      <div className="container">
        <div className="columns">
          <div className="column is-half description-column">
            <h3 className="hero-sub-text">Hi There!</h3>
            <h1 className="title hero-title">I'm Ashan.</h1>
            <p className="subtitle hero-subtitle">
              A web developer & designer with over{" "}
              <span id="experience-years">5 years </span> of experience based in
              <span className="location"> Waterloo, Ontario</span>
            </p>
            <a
              href="#contact"
              className="button is-light is-primary hero-cta site-element-shadow-small"
              id="talk-to-me"
            >
              Talk to me
            </a>
          </div>
          <div className="column is-half animation-column">
            <lottie-player
              src="../../wave.json"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
      </div>
    </div>
    <div id="work" className="site-section">
      <WorkDetail />
    </div>
    <div className="site-section">
      <div class="is-flex-direction-column">
        <div id="worked-with">
          <div class="container">
            <div className="columns" id="work-workedfor">
              <div className="column is-half" id="companies">
                <div className="container">
                  <h3 className="is-font-section-title is-font-color-highlight">
                    I've Worked For
                  </h3>
                  <div className="columns">
                    <div className="column">
                      <img src={yncu} alt="YNCU Logo" width="150px" />
                    </div>
                    <div className="column">
                      <img src={tropusi} alt="Tropusi Logo" width="150px" />
                    </div>
                    <div className="column">
                      <img src={cs} alt="3CS Logo" width="150px" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-half" id="tech-stack">
                <h3 className="is-font-section-title is-font-color-highlight">
                  I'm Experienced In
                </h3>
                <span className="tag is-medium">Javascript / JQuery</span>
                <span className="tag is-medium">Vue / Vuex</span>
                <span className="tag is-medium">React / Redux</span>
                <span className="tag is-medium">Wordpress / Woocommerce</span>
                <span className="tag is-medium">Gridsome / Gatsby</span>
                <span className="tag is-medium">Shopify</span>
                <span className="tag is-medium">GraphQL / REST</span>
                <span className="tag is-medium">PHP / Laravel</span>
                <span className="tag is-medium">
                  Node.js / Express / AdonisJS
                </span>
                <span className="tag is-medium">
                  HTML5
                </span>
                <span className="tag is-medium">
                  CSS / SCSS / Bootstrap / Bulma
                </span>
                <span className="tag is-medium">MongoDB / MySQL</span>
                <span className="tag is-medium">Git</span>
                <span className="tag is-medium">Netlify</span>
                <span className="tag is-medium">Heroku</span>
              </div>
            </div>
          </div>
        </div>

        <div id="contact">
          <div className="container">
            <h3 className="is-font-section-title is-font-color-highlight">
              Contact Me
            </h3>
            <Link to="mailto:ashanudayanga@gmail.com">
              <h4 className="email-address">ashanudayanga@gmail.com</h4>
            </Link>
            <div class="social-links">
              <div class="social-link">
                <Link to="https://www.linkedin.com/in/ashanub/" target="_blank">
                  <div class="icon linkedin site-element-shadow-very-small">
                    <i class="fab fa-linkedin-in fa-2x"></i>
                  </div>
                </Link>
              </div>
              <div class="social-link">
                <Link to="https://github.com/ashanub" target="_blank">
                  <div class="icon github site-element-shadow-very-small">
                    <i class="fab fa-github fa-2x"></i>
                  </div>
                </Link>
              </div>
              <div class="social-link">
                <Link to="https://twitter.com/ashanub/">
                  <div class="icon twitter site-element-shadow-very-small">
                    <i class="fab fa-twitter fa-2x"></i>
                  </div>
                </Link>
              </div>
              <div class="social-link">
                <Link to="https://www.instagram.com/l____o_0____l/">
                  <div class="icon instagram site-element-shadow-very-small">
                    <i class="fab fa-instagram fa-2x"></i>
                  </div>
                </Link>
              </div>
              <div class="social-link">
                <Link to="https://codepen.io/ashanub">
                  <div class="icon codepen site-element-shadow-very-small">
                    <i class="fab fa-codepen fa-2x"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
