import React, { Component } from "react"

// import images
import yonder from "../images/websites/yonder.jpg"
import chint from "../images/websites/chint.jpg"
import learningPedals from "../images/websites/Learning-Pedals.png"
import chambers from "../images/websites/chambers.jpg"
import lankaProperty from "../images/websites/Lanka-Property.jpg"
import polymer from "../images/websites/polymer.jpg"
import jackTree from "../images/websites/jack-tree.jpg"

class WorkDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      currentJob: 0,
    }
  }

  // Modal open close function
  handleModal = event => {
    this.setState({
      modalOpen: !this.state.modalOpen,
    })
  }

  test(jobToOpen) {
    this.setState({
      currentJob: jobToOpen,
    })
  }

  renderViewButton(buttonLink) {
    if (buttonLink) {
      return (
        <div className="view-button-wrap">
          <a
            href={buttonLink}
            target="_blank"
            className="button is-light is-primary hero-cta site-element-shadow-small"
            id="talk-to-me"
          >
            View
          </a>
        </div>
      )
    }
    return
  }

  work = [
    {
      image: yonder,
      description:
        "A travel & tourism website made for Yonder UK that showcases tour packages & destinations the company provides",
      link: "https://yonder.co.uk/",
      tech: ["WordPress", "HTML", "CSS", "JS/JQuery", "PHP", "Bootstrap"],
      name: "Yonder",
      type: "Website"
    },
    {
      image: learningPedals,
      description:
        "The official website of Learning Pedals, Narre Warren, Shows offered driving lesson packages, information, and lets users book appointments",
      link: "https://www.learningpedals.com.au/",
      tech: ["WordPress", "HTML", "CSS", "JS/JQuery", "PHP", "Bootstrap"],
      name: "Learning Pedals",
      type: "Website"
    },
    {
      image: chint,
      description:
        "The corporate website for Chint Energy Sri Lanka. Originally built as a static site which was later converted in to dynamic by implementing Wordpress as a CMS",
      link: "http://chintenergy.lk/",
      tech: ["WordPress", "HTML", "CSS", "JS/JQuery", "PHP", "Bootstrap"],
      name: "Chint Energy",
      type: "Website"
    },
    {
      image: chambers,
      description:
        "Corporate Website of Chambers, Colombo. The site was converted from a static site in to a dynamic site by implementing Wordpress as a CMS",
      link: "http://chamberscolombo.com/",
      tech: ["WordPress", "HTML", "CSS", "JS/JQuery", "PHP"],
      name: "Chambers Colombo",
      type: "Website"
    },
    {
      image: lankaProperty,
      description:
        "A property sale website built for Nihon Property Sales, Sri Lanka",
      link: "https://nihonpropertysales.com/",
      tech: ["WordPress", "HTML", "CSS", "JS/JQuery", "PHP"],
      name: "Nihon Property Sales",
      type: "Website"
    },
    {
      image: polymer,
      description:
        "An internal, web-based task scheduling system built for Polymer Impex, Sri Lanka.",
      link: null,
      tech: ["AdonisJS", "Vue", "Bulma"],
      name: "Polymer Impex",
      type: "Task Scheduling Application"
    },
    {
      image: jackTree,
      description: "Website for Jacktree Lodge. A hotel in Kandy, Sri Lanka",
      link: "http://jacktreelodge.lk/",
      tech: ["WordPress", "HTML", "CSS", "JS/JQuery", "PHP"],
      name: "Jacktree Lodge",
      type: "Website"
    },
  ]

  render() {
    return (
      <>
        <div className="container">
          <h3 className="is-font-section-title is-font-color-highlight">
            Some of My Work
          </h3>
          <div
            className="columns is-desktop is-multiline"
            id="work-cards-section"
          >
            {this.work.map((job, index) => {
              return (
                <div className="column is-one-third">
                  <div
                    className="card site-element-shadow-very-small"
                    onClick={() => {
                      this.handleModal()
                      this.test(index)
                    }}
                  >
                    <div className="card-overlay">
                      <p className="is-font-card-overlay is-font-color-yellow">{job.name}</p>
                      <p className="is-font-body is-font-color-white">{job.type}</p>
                    </div>
                    <div className="card-image">
                      <figure className="image is-16by9">
                        <img src={job.image} alt={job.name} />
                      </figure>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div
          id="work-modal"
          className={"modal" + (this.state.modalOpen ? " is-active" : "")}
        >
          <div className="modal-background" onClick={this.handleModal}></div>

          <div className="modal-content">
            <div
              className="content-box site-element-shadow-small work-details-card"
              id="yonder"
            >
              <div className="card">
                <div className="card-image">
                  <figure className="image is-16by9">
                    <img
                      src={this.work[this.state.currentJob].image}
                      alt={this.work[this.state.currentJob].name}
                    />
                  </figure>
                </div>
                <div className="card-content">
                  <div className="content main-content">
                    <p className="is-font-body">
                      {this.work[this.state.currentJob].description}
                    </p>
                    <div className="tags-button-wrap">
                      <div className="tech-stack">
                        {this.work[this.state.currentJob].tech.map(
                          technology => {
                            return (
                              <span className="tag is-medium">
                                {technology}
                              </span>
                            )
                          }
                        )}
                      </div>
                      {this.renderViewButton(this.work[this.state.currentJob].link)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={this.handleModal}
          ></button>
        </div>
      </>
    )
  }
}

export default WorkDetail
